import React from "react"
import Legend from "../legend"
import { getLocalizedText } from "../../../../shared/locale"
import RatioIndex from "./ratio-index"
import { formatNumber } from "../../../../shared/numbers"

function getCircle(range) {
    const radius = (range.range.high - range.range.low) / 2.0
    const center = range.range.low + radius

    return (
        <circle
            key={range.rank}
            className={`ratio-rank-${range.rank}`}
            cx={center}
            cy={100 - center}
            r={radius}
            clipPath="url(#triangle)"
        />
    )
}

const Ratio = ({
    id,
    language,
    numerator,
    denominator,
    ranks = [],
    points = {},
    indexLegend = "",
    ratioStyle = "simple",
}) => {
    return (
        <div
            className={`ratio-chart-container ${id}-chart-container`}
            style={{ overflow: "visible" }}>
            <svg
                viewBox="0 0 100 100"
                preserveAspectRatio="none"
                style={{ overflow: "visible" }}>
                <defs>
                    <clipPath id="triangle">
                        <path d="M 0,0 L 0,100 L 100,100 Z" />
                    </clipPath>
                </defs>
                <g className={`ratio-chart ${id}-chart`}>
                    {ranks.map((r) => getCircle(r))}
                </g>
                <g className={`ratio-horizontal-ticks`}>
                    <line
                        className={`ratio-horizontal-ticks-line`}
                        x1="0"
                        y1="100"
                        x2="100"
                        y2="100"
                    />
                    {[0, 50, 100].map((value, idx) => (
                        <>
                            <line
                                key={idx}
                                className={`ratio-horizontal-ticks-tick`}
                                x1={value}
                                y1="100"
                                x2={value}
                                y2="104"
                            />
                            <text
                                className={`ratio-horizontal-ticks-label`}
                                transform={`translate(${value} 110)`}>
                                <tspan x="0" y="0">
                                    {value + "%"}
                                </tspan>
                            </text>
                        </>
                    ))}
                </g>
                <g className={`ratio-vertical-ticks`}>
                    <line
                        className={`ratio-vertical-ticks-line`}
                        x1="0"
                        y1="0"
                        x2="0"
                        y2="100"
                    />
                    {[0, 50, 100].map((value, idx) => (
                        <>
                            <line
                                key={idx}
                                className={`ratio-vertical-ticks-tick`}
                                x1={0}
                                y1={100 - value}
                                x2={-4}
                                y2={100 - value}
                            />
                            <text
                                className={`ratio-vertical-ticks-label`}
                                transform={`translate(0 ${100 - value})`}>
                                <tspan x="-8" y="3">
                                    {value + "%"}
                                </tspan>
                            </text>
                        </>
                    ))}
                </g>
                <RatioIndex
                    id={id}
                    ratioStyle={ratioStyle}
                    numerator={numerator}
                    denominator={denominator}
                />
                <g className={`ratio-points ${id}-points`}>
                    {points.map((p) => (
                        <>
                            <line
                                className={`ratio-points-line`}
                                x1={p.x}
                                x2={p.x + 20}
                                y1={100 - p.y}
                                y2={100 - p.y - 20}
                            />
                            <circle
                                cx={p.x + 20}
                                cy={100 - p.y - 20}
                                r={2}
                                className={`ratio-points-line-end`}
                            />
                            <foreignObject
                                className={`ratio-points-label`}
                                x={p.x + 25}
                                y={100 - p.y - 30}>
                                <span>
                                    {getLocalizedText(p.label, language)}
                                </span>
                            </foreignObject>
                        </>
                    ))}
                </g>
            </svg>
            {indexLegend ? (
                <div className={"ratio-index-sample ratio-index-sample-" + id}>
                    <svg>
                        <RatioIndex
                            id={id + "_sample"}
                            ratioStyle={ratioStyle}
                            numerator={{ value: 90 }}
                            denominator={{ value: 90 }}
                            scale={0.15}
                        />
                    </svg>
                    <span>{getLocalizedText(indexLegend, language)}</span>
                </div>
            ) : null}
        </div>
    )
}

export function getRatioType(type) {
    if (type.indexOf("dynamic:") !== -1) {
        const [, ratioType, ratioStyle = "simple"] = type.split(":")
        return [true, ratioType, ratioStyle]
    }
    return [false, type]
}

const ChartRatio = ({
    numerator,
    denominator,
    ratioType = "",
    language,
    subtitle,
    description,
    notes,
    ranks = [],
    points = [],
}) => {
    const [dynamic, type, ratioStyle] = getRatioType(ratioType)

    const ratio =
        denominator.value === 0 ? 0 : numerator.value / denominator.value

    const id =
        type +
        "_" +
        numerator.name.toLowerCase() +
        "_" +
        denominator.name.toLowerCase()

    return (
        <div>
            <div className="section-ratio-header">
                <h1>{getLocalizedText(name, language)}</h1>
                <h2>{getLocalizedText(subtitle, language)}</h2>
                <span>{formatNumber(ratio, 2)}</span>
            </div>
            <div className="section-ratio-chart">
                <span className="ratio-numerator">
                    {getLocalizedText(numerator.name, language)}
                </span>
                <span className="ratio-denominator">
                    {getLocalizedText(denominator.name, language)}
                </span>
                {dynamic ? (
                    <Ratio
                        id={id}
                        ratioStyle={ratioStyle}
                        language={language}
                        numerator={numerator}
                        denominator={denominator}
                        ranks={ranks}
                        points={points}
                        indexLegend={notes}
                    />
                ) : null}
                {ranks.length > 0 ? (
                    <Legend items={ranks} language={language} />
                ) : null}
            </div>
            <p>{getLocalizedText(description, language)}</p>
        </div>
    )
}

export default ChartRatio
