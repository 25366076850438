import React from "react"
import CheckField from "../../views/shared/components/checkfield"
import TextArea from "../../views/shared/components/textarea"
import { checksToObj, objToChecks } from "./fun"
import { arrayToObj, camelize, flatten, unflatten } from "../../shared/fun"

export const formInputs = [
    { label: "Birth control pill" },
    { label: "Proton pump inhibitors" },
    { label: "Antidepressant" },
    { label: "Antihypertensive" },
    { label: "Statins" },
    { label: "Hormone replacement therapy" },
    { label: "Antibiotics" },
    { label: "Anticoagulants" },
    { label: "Hypoglycemics" },
    { label: "Chemotherapeutics" },
    { label: "Biological Medicines" },
    { label: "Intestinal anti inflammatories" },
]

export const drugName = (drug, type) =>
    type ? `${camelize(drug)}_${type}` : camelize(drug)

export const allDrugs = (type) =>
    formInputs.map((drug) => drugName(drug.label, type))

export const model = {
    additionalDrugsInfo: "",
    additionalAllergiesInfo: "",
    additionalNotes: "",
    ...checksToObj(formInputs, {
        inUse: false,
        notTolerated: false,
    }),
}

const internalDetailsToModel = (details, empty) => {
    if (details) {
        const { drugs, patientId, barcode, receivedDate, ...rest } = details
        return {
            ...empty,
            ...arrayToObj(
                drugs,
                (x) => x.name,
                (x) => x.data,
            ),
            ...rest,
        }
    }
    return empty
}

export const detailsToModel = (data) =>
    flatten(internalDetailsToModel(data, model))

export const modelToDetails = (data) => objToChecks(unflatten(data))

const DrugField = ({ formist, label }) => {
    const inuseName = drugName(label, "inUse")
    const nottoleratedName = drugName(label, "notTolerated")
    return (
        <div className="row">
            <div className="col">
                <label htmlFor="allergy">{label}</label>
            </div>
            <div className="col">
                <CheckField
                    className="form-check-input-patient"
                    id={inuseName}
                    {...formist.getFieldProps(inuseName)}
                />
            </div>
            <div className="col">
                <CheckField
                    className="form-check-input-patient"
                    id={nottoleratedName}
                    {...formist.getFieldProps(nottoleratedName)}
                />
            </div>
        </div>
    )
}

const PatientDrugsForm = ({ formist }) => {
    return (
        <>
            <div className="row form-group">
                <div className="col"> </div>
                <div className="col">In use</div>
                <div className="col">Not tolerated</div>
            </div>
            {formInputs.map((x, index) => {
                return <DrugField key={index} formist={formist} {...x} />
            })}
            <div className="form-group col">
                <div className="row">
                    <TextArea
                        rows="4"
                        cols="70"
                        placeholder="Additional drugs info..."
                        {...formist.getFieldProps("additionalDrugsInfo")}
                    />
                </div>
                <div className="row">
                    <TextArea
                        rows="4"
                        cols="70"
                        placeholder="Additional allergies and intolerances..."
                        {...formist.getFieldProps("additionalAllergiesInfo")}
                    />
                </div>
                <div className="row">
                    <TextArea
                        rows="4"
                        cols="70"
                        placeholder="Note..."
                        {...formist.getFieldProps("additionalNotes")}
                    />
                </div>
            </div>
        </>
    )
}

export default PatientDrugsForm
