import React, { useEffect, useState } from "react"
import SectionHeader from "../shared/components/section-header.jsx"
import Filter from "./filter.jsx"
import { mkApi } from "./api.js"
import {
    useStateContext,
    withStateContext,
} from "../../shared/state-context.jsx"
import { initialState, reducer, statuses } from "./store.js"
import Kanban from "./kanban.jsx"

function SupervisorDashboard() {
    const { state, dispatch } = useStateContext()
    const { analyses, referral, reporter, pattern, type } = state
    const api = mkApi(dispatch)

    const [reporters, setReporters] = useState([])

    function loadAll() {
        Promise.all(
            statuses.map((status) =>
                api.loadByStatus({
                    status,
                    type,
                    pattern,
                    pageNumber: 0,
                    pageSize: 10,
                    sortBy: "supervisor",
                }),
            ),
        )
    }
    function load({
        referralFilter,
        reporterFilter,
        searchFilter,
        typeFilter,
    }) {
        Promise.all(
            statuses.map((status) =>
                api.loadByStatus({
                    status,
                    type: typeFilter ?? type,
                    pattern: searchFilter ?? pattern,
                    reporter: reporterFilter ?? reporter,
                    referral: referralFilter ?? referral,
                    pageNumber: 0,
                    pageSize: 10,
                    sortBy: "supervisor",
                }),
            ),
        )
    }

    useEffect(() => {
        api.loadReporters().then((r) => {
            setReporters(r.items)
        })
        loadAll()
    }, [])

    function onFilter(filterType, value) {
        switch (filterType) {
            case "type":
                load({
                    typeFilter: value,
                })
                break
            case "referral":
                load({
                    referralFilter: value,
                })
                break
            case "reporter":
                load({ reporterFilter: value })
                break
            // case "supplier":
            //     fetchData({
            //         page: 0,
            //         supplier: value,
            //     })
            //     break
            default:
                break
        }
    }

    return (
        <div id="dashboard-supervisor">
            <SectionHeader title="Supervisor Dashboard" />
            <div className="mt-4">
                <Filter
                    type={type}
                    referral={referral}
                    reporter={reporter}
                    onFilter={onFilter}
                    reporters={reporters ?? []}
                    onChangeSearch={(e) =>
                        load({ searchFilter: e.target.value })
                    }
                    loadReferrals={(pattern) => api.loadReferrals(pattern)}
                />
            </div>
            <div className="row">
                <Kanban
                    reporters={reporters ?? []}
                    analyses={analyses}
                    loadAll={loadAll}
                />
            </div>
        </div>
    )
}

export default withStateContext(SupervisorDashboard, initialState, reducer)
