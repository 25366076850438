import React from "react"
import { mkApi } from "./api"
import SectionHeader from "../shared/components/section-header"
import { CommentAction } from "@atlaskit/comment"
import startCase from "lodash/startCase"
import withFetch from "../shared/components/fetch"
import Button from "@atlaskit/button"
import { routes } from "../../shared/routing"

const drugsLabels = {
    birthControlPill: "Birth control pill",
    protonPumpInhibitors: "Proton pump inhibitors",
    antidepressant: "Antidepressant",
    antihypertensive: "Antihypertensive",
    statins: "Statins",
    hormoneReplacementTherapy: "Hormone replacement therapy",
    antibiotics: "Antibiotics",
    anticoagulants: "Anticoagulants",
    hypoglycemics: "Hypoglycemics",
    chemotherapeutics: "Chemotherapeutics",
    biologicalMedicines: "Biological Medicines",
    intestinalAntiInflammatories: "Intestinal anti inflammatories",
}

function boolToYesNo(b) {
    return b ? "yes" : "no"
}

export const EmailSummary = ({ geneticAnalysis }) => {
    const status = {
        isApprovedSentToPatient:
            geneticAnalysis.shouldSendToPatient ||
            !!geneticAnalysis.registeredEmailForPatient,
        isApprovedSentToReferral:
            geneticAnalysis.shouldSendToReferral ||
            !!geneticAnalysis.registeredEmailForReferral,
    }

    return (
        <>
            <SectionHeader title="Registration email" />
            <div className="form-group col" style={{ fontSize: "16px" }}>
                <label>Patient:</label>{" "}
                <span>{boolToYesNo(status.isApprovedSentToPatient)}</span>
                <br />
                <label>Referral:</label>{" "}
                <span>{boolToYesNo(status.isApprovedSentToReferral)}</span>
            </div>
        </>
    )
}

export const PatientSummary = ({ patientData }) => {
    const { firstName, lastName, email, anonymous } = patientData

    return anonymous ? (
        <SectionHeader title="Anonymous Patient" />
    ) : (
        <>
            <SectionHeader title="Patient" />
            <div className="form-group col" style={{ fontSize: "16px" }}>
                <label>First Name:</label> <span>{firstName}</span>
                <br />
                <label>Last Name:</label> <span>{lastName}</span>
                <br />
                <label>Email:</label> <span>{email}</span>
                <br />
            </div>
        </>
    )
}

export const PatientPhysicalDescriptionSummary = ({ patientData, onEdit }) => {
    const {
        age,
        weight,
        height,
        ethnicityType,
        genderType,
        exerciseFrequencyType,
        smokerType,
        smokerNoMoreFrom,
        exerciseHoursWeekly,
        exerciseDescription,
    } = patientData
    return (
        <>
            <SectionHeader title="Physical Description" />
            <div className="form-group col" style={{ fontSize: "16px" }}>
                <label>Age:</label> <span>{age}, </span>
                <label>Weight:</label> <span>{weight}, </span>
                <label>Height:</label> <span>{height}, </span>
                <label>Gender:</label> <span>{genderType}, </span>
                <label>Ethnicity:</label> <span>{ethnicityType}, </span>
                <label>Excercise:</label>{" "}
                <span>
                    {exerciseFrequencyType}, {exerciseHoursWeekly} hours,{" "}
                    {exerciseDescription},{" "}
                </span>
                <label>Smoker:</label>{" "}
                <span>
                    {smokerType}{" "}
                    {smokerNoMoreFrom ? "(" + smokerNoMoreFrom + ")" : ""}
                </span>
            </div>
            {onEdit && <CommentAction onClick={onEdit}>Edit</CommentAction>}
        </>
    )
}

export const PatientGuardianSummary = ({ patientData, onEdit }) => {
    const { firstName, lastName, guardian } = patientData
    return (
        <>
            <SectionHeader title="Guardian" />
            <div className="form-group col" style={{ fontSize: "16px" }}>
                <label>Legal guardian of a minor:</label>{" "}
                <span>{guardian}. </span>
                {guardian === "yes" && (
                    <>
                        <label>First Name:</label> <span>{firstName}, </span>
                        <label>Last Name:</label> <span>{lastName}</span>
                    </>
                )}
            </div>
            {onEdit && <CommentAction onClick={onEdit}>Edit</CommentAction>}
        </>
    )
}

export const ReferralSummary = ({ referral, onEdit }) => {
    return referral ? (
        <>
            <SectionHeader title="Referral" />
            <div className="form-group col" style={{ fontSize: "16px" }}>
                <label>Referral Code:</label> <span>{referral}</span>
            </div>
            {onEdit && <CommentAction onClick={onEdit}>Edit</CommentAction>}
        </>
    ) : null
}

const renderDiseasesGroup = (group, filter) => {
    return (
        <>
            <label>{startCase(group.name)}:</label>{" "}
            {group.items
                .filter(filter)
                .map((disease) => startCase(disease.name))
                .join(", ")}
            <br />
        </>
    )
}

const typeFilter = (selector) => (d) => d.data[selector]

const atLeastOne = (selector) => (data) =>
    data.items.filter(typeFilter(selector)).length > 0

const renderDiseasesType = (data, type) => {
    return (
        <>
            <h3>{startCase(type)}</h3>
            {data
                .filter(atLeastOne(type))
                .map((g) => renderDiseasesGroup(g, typeFilter(type)))}
        </>
    )
}

// eslint-disable-next-line react/display-name
const renderDiseases = (data) => (type) =>
    (
        <div className="form-group col" style={{ fontSize: "16px" }}>
            {data.filter(atLeastOne(type)).length
                ? renderDiseasesType(data, type)
                : null}
        </div>
    )

export const PatientDiseasesSummary = ({ patientData, onEdit }) => {
    return (
        <>
            <SectionHeader title="Diseases" />
            {["symtoms", "familiarity"].map(renderDiseases(patientData))}
            {onEdit && <CommentAction onClick={onEdit}>Edit</CommentAction>}
        </>
    )
}

const renderDrugsType = (data, type) => {
    return (
        <>
            <h3>{startCase(type)}</h3>
            {data
                .filter(typeFilter(type))
                .map((drug) => drugsLabels[drug.name])
                .join(", ")}
        </>
    )
}

// eslint-disable-next-line react/display-name
const renderDrugs = (data) => (type) =>
    (
        <div className="form-group col" style={{ fontSize: "16px" }}>
            {data.filter(typeFilter(type)).length
                ? renderDrugsType(data, type)
                : null}
        </div>
    )

// eslint-disable-next-line react/display-name
const renderAdditionalDrugs = (data) => (type) =>
    (
        <div className="form-group col" style={{ fontSize: "16px" }}>
            <label>{startCase(type)}:</label> <span>{data[type]}</span>
        </div>
    )

export const PatientDrugsSummary = ({
    patientData,
    additionalData,
    onEdit,
}) => {
    return (
        <>
            <SectionHeader title="Drugs" />
            {["inUse", "notTolerated"].map(renderDrugs(patientData))}
            <hr />
            {[
                "additionalDrugsInfo",
                "additionalAllergiesInfo",
                "additionalNotes",
            ].map(renderAdditionalDrugs(additionalData))}
            {onEdit && <CommentAction onClick={onEdit}>Edit</CommentAction>}
        </>
    )
}

export const GeneticAnalysisSummary = ({
    barcode,
    templateIds,
    layoutIds,
    templates,
    layouts,
    analyzed,
    referral,
    referrals,
}) => {
    const templateNames = templates.items
        .filter((x) => templateIds.includes(x.id))
        .map((x) => x.name)
        .join(", ")

    const layoutNames = layouts.items
        .filter((x) => layoutIds.includes(x.id))
        .map((x) => x.name)
        .join(", ")

    const referralNames = referrals.items
        .filter((x) => x.code === referral)
        .map((x) => x.name)
        .join(", ")

    return (
        <>
            <SectionHeader title="Genetic Analysis" />
            <div className="form-group col" style={{ fontSize: "16px" }}>
                <label>Barcode:</label> <span>{barcode}</span>
                <br />
                <label>Referral:</label> <span>{referralNames}</span>
                <br />
                <label>Templates:</label> <span>{templateNames}</span>
                <br />
                <label>Layouts:</label> <span>{layoutNames}</span>
                <br />
                <label>Analysis:</label>{" "}
                <span>{(analyzed && "DONE") || "TO BE DONE"}</span>
            </div>
        </>
    )
}

export const Summary = ({ history, match, data }) => {
    const barcode = match.params.id
    const [geneticAnalysis, templates, layouts, referrals] = data[0]
    const { drugs, ...additional } = geneticAnalysis.patientDetails.patientDrugs
    return (
        <>
            <h1>Registration Summary</h1>
            <div className="col-md-6">
                <GeneticAnalysisSummary
                    barcode={barcode}
                    templateIds={geneticAnalysis.templateIds}
                    layoutIds={geneticAnalysis.layoutIds}
                    templates={templates}
                    layouts={layouts}
                    analyzed
                    referral={geneticAnalysis.referral}
                    referrals={referrals}
                />
                <PatientSummary
                    patientId={geneticAnalysis.patientId}
                    patientData={geneticAnalysis.patientDetails.patient}
                />
                <ReferralSummary referral={geneticAnalysis.referral} />
                <PatientPhysicalDescriptionSummary
                    patientId={geneticAnalysis.patientId}
                    patientData={
                        geneticAnalysis.patientDetails
                            .patientPhysicalDescription
                    }
                />
                <PatientGuardianSummary
                    patientId={geneticAnalysis.patientId}
                    patientData={geneticAnalysis.patientDetails.patientGuardian}
                />
                <PatientDiseasesSummary
                    patientId={geneticAnalysis.patientId}
                    patientData={
                        geneticAnalysis.patientDetails.patientDiseases.groups
                    }
                />
                <PatientDrugsSummary
                    patientId={geneticAnalysis.patientId}
                    patientData={drugs}
                    additionalData={additional}
                />
                <EmailSummary geneticAnalysis={geneticAnalysis} />
            </div>
            <Button
                appearance="primary"
                onClick={() =>
                    history.push(routes.geneticAnalyses.edit.buildUrl(barcode))
                }>
                Back
            </Button>
        </>
    )
}

const loadData = ({ api, id }) => {
    return api.loadFull(id)
}

const loadTemplates = ({ api }) => {
    return api.loadTemplates()
}

const loadLayouts = ({ api }) => {
    return api.loadLayouts()
}

const loadReferrals = ({ api }) => {
    return api.loadReferrals()
}

export default withFetch(Summary, mkApi(), loadData, undefined, undefined, [
    loadTemplates,
    loadLayouts,
    loadReferrals,
])
