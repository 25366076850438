import React, { useEffect } from "react"
import TextField from "../shared/components/textfield"
import RadioCheckList from "../shared/components/radio-check-list"
import { toIso, toIta } from "../../shared/dates"

export const model = {
    age: "",
    genderType: "unspecified",
    menopauseType: "notapplicable",
    weight: "",
    height: "",
    ethnicityType: "unspecified",
    exerciseFrequencyType: "unspecified",
    smokerType: "unspecified",
    smokerNoMoreFrom: "",
    lastMenstrualCycleDate: "",
    drunkWaterDaily: "unspecified",
    sleepingHours: "",
    bodyImpedenceAssesment: { rz: "", xc: "" },
    medilitePoints: "",
    pittsburghPoints: "unspecified",
    ipaqPoints: "unspecified",
}

function decodeBia(bia) {
    const [rz, xc] = bia.split(",")
    return { rz, xc }
}

function encodeBia({ rz, xc }) {
    return rz && xc ? `${rz},${xc}` : ""
}

export const detailsToModel = (data) => ({
    ...data,
    bodyImpedenceAssesment: data.bodyImpedenceAssesment
        ? decodeBia(data.bodyImpedenceAssesment)
        : { rz: "", xc: "" },
    lastMenstrualCycleDate: data.lastMenstrualCycleDate
        ? toIta(data.lastMenstrualCycleDate)
        : "",
})

export const modelToDetails = (data) => ({
    ...data,
    bodyImpedenceAssesment: encodeBia(data.bodyImpedenceAssesment),
    lastMenstrualCycleDate: data.lastMenstrualCycleDate
        ? toIso(data.lastMenstrualCycleDate)
        : "",
})

const calcBMI = ({ weight, height }) => {
    if (weight && height) {
        const heightM = height / 100.0
        return weight / heightM / heightM
    }
    return NaN
}

const PatientPhysicalDescriptionForm = ({ formist, anonymous = false }) => {
    const height = formist.getFieldProps("height").value
    const weight = formist.getFieldProps("weight").value
    const gender = formist.getFieldProps("genderType").value

    const bmi = calcBMI({
        weight,
        height,
    })
    const bia = formist.getFieldProps("bodyImpedenceAssesment").value
    const biaPhaseAngle =
        bia.rz && bia.xc
            ? (
                  (Math.atan2(Number(bia.rz), Number(bia.xc)) * 180.0) /
                  Math.PI
              ).toFixed(2) + "°"
            : ""
    useEffect(() => {
        if (gender === "male") formist.change("menopauseType", "notapplicable")
    }, [gender])
    return (
        <>
            <div className="form-row">
                <TextField
                    label="Age"
                    width="col-sm-4"
                    disabled={!anonymous}
                    readOnly={!anonymous}
                    {...formist.getFieldProps("age")}
                />
            </div>
            <div className="form-row">
                <TextField
                    label="Weight"
                    width="col-sm-4"
                    {...formist.getFieldProps("weight")}
                />
                <TextField
                    label="Height (cm)"
                    width="col-sm-4"
                    {...formist.getFieldProps("height")}
                />
            </div>
            <div className="form-row">
                <RadioCheckList
                    label="Gender"
                    width="col-sm-4"
                    items={[
                        { label: "Male", value: "male" },
                        { label: "Female", value: "female" },
                    ]}
                    {...formist.getFieldProps("genderType")}
                />
                <RadioCheckList
                    label="Menopause"
                    width="col-sm-2"
                    style={{
                        display: gender === "female" ? "block" : "none",
                    }}
                    items={[
                        { label: "Pre", value: "pre" },
                        { label: "Post", value: "post" },
                    ]}
                    {...formist.getFieldProps("menopauseType")}
                />
                <TextField
                    label="Last Menstrual Cycle"
                    width="col-sm-2"
                    style={{
                        display: gender === "female" ? "block" : "none",
                    }}
                    {...formist.getFieldProps("lastMenstrualCycleDate")}
                />
            </div>
            <div className="form-row">
                <div className="form-group col-sm-4">
                    <label style={{ fontWeight: "bold" }}>BMI:</label>
                    &nbsp;
                    <label>{(isNaN(bmi) && "--") || bmi.toFixed(2)}</label>
                </div>
            </div>
            <div className="form-row">
                <RadioCheckList
                    label="Ethnicity"
                    items={[
                        { label: "Caucasian", value: "caucasian" },
                        { label: "African", value: "african" },
                        { label: "Amerindian", value: "amerindian" },
                        { label: "Asian", value: "asian" },
                    ]}
                    {...formist.getFieldProps("ethnicityType")}
                />
            </div>
            <div className="form-row">
                <RadioCheckList
                    label="Exercise Frequency"
                    items={[
                        { label: "Low (1/w)", value: "low" },
                        { label: "Average (3/w)", value: "average" },
                        { label: "High (6/w)", value: "high" },
                    ]}
                    {...formist.getFieldProps("exerciseFrequencyType")}
                />
            </div>
            <div className="form-row">
                <div className="form-group col-sm-2">
                    <TextField
                        type="number"
                        label="Exercise Hours (per week)"
                        {...formist.getFieldProps("exerciseHoursWeekly")}
                    />
                </div>
                <div className="form-group col-sm-4">
                    <TextField
                        label="Exercise Type"
                        {...formist.getFieldProps("exerciseDescription")}
                    />
                </div>
            </div>
            <div className="form-row">
                <RadioCheckList
                    label="Smoker"
                    width="col-sm-4"
                    items={[
                        { label: "Yes", value: "yes" },
                        { label: "No", value: "no" },
                        { label: "No more", value: "noMore" },
                    ]}
                    {...formist.getFieldProps("smokerType")}
                />
                <TextField
                    label=" "
                    width="col-sm-4"
                    placeholder="from..."
                    {...formist.getFieldProps("smokerNoMoreFrom")}
                />
            </div>
            <div className="form-row">
                <div className="form-group col-sm-2">
                    <RadioCheckList
                        label="Daily drunk water"
                        items={[
                            { label: "<1 l", value: "low" },
                            { label: "1-2 l", value: "average" },
                            { label: ">2 l", value: "high" },
                        ]}
                        {...formist.getFieldProps("drunkWaterDaily")}
                    />
                </div>
                <div className="form-group col-sm-2">
                    <TextField
                        label="Sleeping hours"
                        {...formist.getFieldProps("sleepingHours")}
                    />
                </div>
            </div>
            <label style={{ fontWeight: "bold" }}>
                Body Impedence Assesment
            </label>
            <div className="form-row">
                <div className="form-group col-sm-2">
                    <TextField
                        type="number"
                        label="Rz"
                        value={
                            formist.getFieldProps("bodyImpedenceAssesment")
                                .value.rz
                        }
                        onChange={(e) => {
                            formist.change("bodyImpedenceAssesment", {
                                ...formist.getFieldProps(
                                    "bodyImpedenceAssesment",
                                ).value,
                                rz: e.target.value,
                            })
                        }}
                        error={
                            formist.getFieldProps("bodyImpedenceAssesment")
                                .error
                        }
                    />
                </div>
                <div className="form-group col-sm-2">
                    <TextField
                        type="number"
                        label="Xc"
                        value={
                            formist.getFieldProps("bodyImpedenceAssesment")
                                .value.xc
                        }
                        onChange={(e) => {
                            formist.change("bodyImpedenceAssesment", {
                                ...formist.getFieldProps(
                                    "bodyImpedenceAssesment",
                                ).value,
                                xc: e.target.value,
                            })
                        }}
                    />
                </div>
                <div className="form-group col-sm-2">
                    <label>PA: </label>
                    <div>{biaPhaseAngle}</div>
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-sm-2">
                    <TextField
                        type="number"
                        label="MEDILITE"
                        {...formist.getFieldProps("medilitePoints")}
                    />
                </div>
                <RadioCheckList
                    label="PITTSBURGH"
                    width="col-sm-2"
                    items={[
                        { label: "<5", value: "low" },
                        { label: ">5", value: "high" },
                    ]}
                    {...formist.getFieldProps("pittsburghPoints")}
                />
                <RadioCheckList
                    label="IPAQ"
                    width="col-sm-1"
                    items={[
                        { label: "<700 MET", value: "low" },
                        { label: "700 - 2520 MET", value: "average" },
                        { label: ">2520 MET", value: "high" },
                    ]}
                    {...formist.getFieldProps("ipaqPoints")}
                />
            </div>
        </>
    )
}

export default PatientPhysicalDescriptionForm
