import React, { useEffect, useState } from "react"
import Filter from "./todo-filter"
import { routes } from "../../shared/routing"
import { Link } from "react-router-dom"
import PaginatedTable from "../shared/components/paginated-table"
import { range } from "lodash"
import { useStateContext, withStateContext } from "../../shared/state-context"
import { initialState, reducer } from "./todo-store"
import { mkApi } from "./api"
import { toIta, today, parse } from "../../shared/dates"
import MarkAsDoneBarcode from "../dashboard/mark-as-done-barcode"
import { success } from "../../shared/notifications"
import StatusLink from "../shared/components/status-link"
import PDFMenu from "../dashboard/pdf-menu"
import { showError } from "../dashboard"
import { download } from "../../shared/ajax"
import { hasPDF, useInProgress } from "../dashboard/pdf-link"
import EmailMenu from "../dashboard/email-menu"
import DropdownMenu, { DropdownItemGroup } from "@atlaskit/dropdown-menu"
import {
    recreatePDFMenuItem,
    removeBarcodeMenuItem,
} from "../dashboard/actions"
import RemoveBarcode from "../dashboard/remove-barcode"
import { isComplete, isDone } from "../geneticanalyses/schemas"

const head = {
    cells: [
        {
            key: "barcode",
            content: "Barcode",
        },
        {
            key: "patient",
            content: "Patient",
        },
        {
            key: "due",
            content: "Due in",
        },
        {
            key: "referral",
            content: "Referral",
        },
        {
            key: "report-show",
            content: "Report",
        },
        { key: "pdf", content: "PDF" },
        { key: "email", content: "" },
        {
            key: "markasdone",
            content: "",
        },
        { key: "actions", content: "" },
    ],
}

function getDueStatus(dueDate, done) {
    if (done) return "done"
    if (!dueDate) return "notset"
    const now = parse(today)
    const due = parse(toIta(dueDate))
    const diff = due.diff(now, "days")
    if (diff < 0) return "overdue"
    if (diff <= 2) return "coupleofdays"
    if (diff <= 5) return "week"
    return "morethanaweek"
}

function getDueLabel(dueDate, done) {
    if (done) return "Done"
    if (!dueDate) return "Not set"
    const now = parse(today)
    const due = parse(toIta(dueDate))
    const diff = due.diff(now, "days")
    if (diff < 0) return "Overdue"
    if (diff === 0) return "Today"
    if (diff === 1) return "1 day"
    if (diff <= 5) return `${diff} days`
    return "N days"
}

function getDueTooltip(dueDate, doneDate) {
    if (doneDate) return toIta(doneDate)
    if (dueDate) return toIta(dueDate)
    return ""
}

function ToDo() {
    const [doneBarcode, setDoneBarcode] = useState(null)
    const [downloading, setDownloading] = useState(null)
    const [removingBarcode, setRemovingBarcode] = useState(null)
    const [barcodeData, setBarcodeData] = useState(null)
    const [layouts, setLayouts] = useState([])
    const { state, dispatch } = useStateContext()
    const api = mkApi(dispatch)
    const {
        analyses = [],
        isLoading,
        pageNumber,
        totalPages,
        totalItems,
        type,
        referral,
        patient,
        pattern,
        error,
    } = state

    const fetchData = ({
        page = pageNumber,
        typeFilter = type,
        referralFilter = referral,
        patientFilter = patient,
        search = pattern,
    } = {}) => {
        api.loadToDos({
            pageNumber: page,
            sortBy: "",
            type: typeFilter,
            referral: referralFilter,
            patient: patientFilter,
            pattern: search.length >= 2 ? search : null,
        })
    }

    useEffect(() => {
        fetchData()
        api.loadLayouts().then((l) => setLayouts(l.items))
    }, [])

    useInProgress(analyses, () =>
        fetchData({
            page: pageNumber,
            search: pattern,
            typeFilter: type,
            referralFilter: referral,
            patientFilter: patient,
        }),
    )

    function onFilter(filterType, value) {
        switch (filterType) {
            case "type":
                fetchData({
                    page: 0,
                    typeFilter: value,
                })
                break
            case "referral":
                fetchData({
                    page: 0,
                    referralFilter: value,
                })
                break
            case "patient":
                fetchData({
                    page: 0,
                    patientFilter: value,
                })
                break
            default:
                break
        }
    }

    const onChangePage = (e, page) => {
        fetchData({ page })
    }

    const onChangeSearch = (e) => {
        fetchData({ page: 0, search: e.target.value })
    }

    const markAsDone = (barcode) => {
        setDoneBarcode(barcode)
    }

    const confirmMarkAsDone = () => {
        api.markAsDone(doneBarcode)
            .then(() => {
                success("Barcode marked as done!")
                fetchData({})
            })
            .catch((e) => {
                showError(e.message)
            })
            .finally(() => {
                cancelMarkAsDone()
            })
    }

    const cancelMarkAsDone = () => {
        setDoneBarcode(null)
    }

    function createPDF(barcode, layout) {
        api.pdf(barcode, layout)
            .then(() => {
                success("PDF is under construction...")
                fetchData({})
            })
            .catch((e) => {
                showError(e)
            })
    }

    function downloadPDF(barcode, layout, reportMode) {
        setDownloading(barcode)
        const url = api.downloadPDF(barcode, layout, reportMode)
        download(url, barcode, "pdf")
            .catch((e) => showError(e))
            .finally(() => setDownloading(null))
    }

    function sendEmail(
        barcode,
        layout,
        to,
        patientAttachmentRule,
        referralAttachmentRule,
        callback,
    ) {
        api.sendEmail(
            barcode,
            layout,
            to,
            patientAttachmentRule,
            referralAttachmentRule,
        )
            .then(() => {
                success("Report successfully sent by email!")
            })
            .catch((e) => {
                showError(e)
            })
            .finally(() => {
                if (callback) callback()
                fetchData({})
            })
    }

    const recreatePDFHandler = (item) => (e) => {
        e.preventDefault()
        api.allpdf(item.barcode)
            .then(() => {
                success("PDF is under construction...")
                fetchData({})
            })
            .catch((e) => {
                error(e.Message)
            })
    }

    const removeBarcodeHandler = (item) => (e) => {
        e.preventDefault()
        setRemovingBarcode(item.barcode)
    }

    const cancelRemoveBarcode = () => {
        setBarcodeData(null)
        setRemovingBarcode(null)
    }

    const confirmRemoveBarcode = () => {
        api.removeBarcode(removingBarcode, !!barcodeData)
            .then((result) => {
                if (
                    !barcodeData &&
                    (result.patientData ||
                        result.dnaAnalysis ||
                        result.macroBiotaAnalysis)
                ) {
                    setBarcodeData(result)
                } else {
                    success("Barcode removed!")
                    cancelRemoveBarcode()
                    fetchData({})
                }
            })
            .catch((e) => {
                showError(e)
            })
    }

    const mkRows = (items) => {
        return items.map((item, index) => {
            const sendableLayouts = (item.layoutIds || []).filter((l) =>
                hasPDF(item, l),
            )
            return {
                key: `row-${index}-${item.id}`,
                cells: [
                    {
                        content: (
                            <Link
                                to={{
                                    pathname:
                                        routes.geneticAnalyses.edit.buildUrl(
                                            item.barcode,
                                        ),
                                }}>
                                {item.barcode}
                            </Link>
                        ),
                    },
                    {
                        content: `${item.patientFirstName || ""} ${
                            item.patientLastName || ""
                        }`,
                    },
                    {
                        content: (
                            <span
                                data-tooltip={getDueTooltip(
                                    item.dueDate,
                                    isDone(item) ? item.doneDate : null,
                                )}
                                className={`barcode-duestatus barcode-duestatus-${getDueStatus(
                                    item.dueDate,
                                    isDone(item),
                                )}`}>
                                {getDueLabel(item.dueDate, isDone(item))}
                            </span>
                        ),
                    },
                    {
                        content: item.referral,
                    },
                    {
                        content: (
                            <>
                                <div>
                                    <StatusLink
                                        item={item}
                                        withLink={isComplete}
                                        linkText="Show"
                                        to={{
                                            pathname:
                                                routes.reports.show.buildUrl(
                                                    item.barcode,
                                                ),
                                        }}
                                    />
                                </div>
                            </>
                        ),
                    },
                    {
                        content: (
                            <PDFMenu
                                layouts={item.layoutIds}
                                allLayouts={layouts}
                                barcode={item.barcode}
                                status={item.status}
                                pdf={item.pdf}
                                downloading={downloading}
                                onCreate={createPDF}
                                onDownload={downloadPDF}
                            />
                        ),
                    },
                    {
                        content: (
                            <EmailMenu
                                layouts={sendableLayouts}
                                allLayouts={layouts}
                                barcode={item.barcode}
                                emails={item.reportEmails}
                                rules={item.sendRules}
                                onSend={sendEmail}
                            />
                        ),
                    },
                    {
                        content: (
                            <a
                                href={isDone(item) ? undefined : "#"}
                                onClick={
                                    !isDone(item)
                                        ? () => markAsDone(item.barcode)
                                        : undefined
                                }>
                                Mark as Done
                            </a>
                        ),
                    },
                    {
                        content: (
                            <DropdownMenu
                                trigger="Actions"
                                triggerType="button">
                                <DropdownItemGroup>
                                    {recreatePDFMenuItem(
                                        item,
                                        recreatePDFHandler,
                                    )}
                                    {removeBarcodeMenuItem(
                                        item,
                                        removeBarcodeHandler,
                                    )}
                                </DropdownItemGroup>
                            </DropdownMenu>
                        ),
                    },
                ],
            }
        })
    }

    const removeBarcodeDataList = (
        barcodeData
            ? [
                  barcodeData.patientData
                      ? "Patient description, diseases, drugs"
                      : "",
                  barcodeData.dnaAnalysis ? "DNA analyses results" : "",
                  barcodeData.macroBiotaAnalysis
                      ? "Macrobiota analyses results"
                      : "",
              ]
            : []
    )
        .filter((w) => w !== "")
        .join(", ")

    const removeBarcodeWarning = barcodeData
        ? `Barcode ${removingBarcode} has some data associated (${removeBarcodeDataList}). Please confirm you are aware that this data is going to be removed too!`
        : `Are you sure you want to remove the barcode ${removingBarcode}?`

    const pages = range(1, totalPages + 1)
    return (
        <>
            <div className="mt-4">
                <Filter
                    type={type}
                    referral={referral}
                    patient={patient}
                    onFilter={onFilter}
                    onChangeSearch={onChangeSearch}
                    totalItems={totalItems}
                    loadPatients={(pattern) => api.loadPatients(pattern)}
                    loadReferrals={(pattern) => api.loadReferrals(pattern)}
                />
            </div>
            <div className="row">
                <PaginatedTable
                    type="reporter-todo"
                    head={head}
                    rows={mkRows(analyses)}
                    isLoading={isLoading}
                    pageNumber={pageNumber || 1}
                    pages={pages}
                    onChangePage={onChangePage}
                />
            </div>
            {error && (
                <div className="alert alert-danger mt-5">{error.Message}</div>
            )}
            <MarkAsDoneBarcode
                enabled={doneBarcode}
                onConfirm={confirmMarkAsDone}
                onCancel={cancelMarkAsDone}
                warningMessage={`Are you sure you want to mark barcode ${doneBarcode} as done?`}
            />
            <RemoveBarcode
                enabled={removingBarcode}
                onConfirm={confirmRemoveBarcode}
                onCancel={cancelRemoveBarcode}
                warningMessage={removeBarcodeWarning}
            />
        </>
    )
}

export default withStateContext(ToDo, initialState, reducer)
