import { analysisTypes } from "../../analysisTypes.js"
import { SelectField } from "../shared/components/selectfield.jsx"
import React from "react"
import AutocompleteSelectField from "../shared/components/autocomplete-selectedfield.jsx"
import InlineSearch from "../shared/components/inline-search.jsx"

const SupervisorDashboardFilter = ({
    type,
    referral,
    reporter,
    reporters,
    onFilter,
    onChangeSearch,
    loadReferrals,
}) => {
    const filterAnalysisTypes = [
        {
            label: "All Analysis Type",
            value: "",
        },
        ...analysisTypes,
    ]
    const allReferrals = [
        {
            label: "All Referrals",
            value: "",
        },
    ]
    const allSuppliers = [{ label: "All Suppliers", value: "" }]
    const allReporters = [
        {
            label: "All Reporters",
            value: "",
        },
        ...reporters.map((r) => ({ label: r.name, value: r.email })),
    ]

    function setTypeFilter(evt) {
        onFilter("type", evt.target.value)
    }
    function setReferralFilter(evt) {
        onFilter("referral", evt.target.value)
    }
    function setReporterFilter(evt) {
        onFilter("reporter", evt.target.value)
    }
    function todoSupplier(evt) {
        onFilter("todoSupplier", evt.target.value)
    }

    const referralsOptions = referral
        ? [referral, ...allReferrals]
        : allReferrals

    function autocompleteReferrals(pattern) {
        if (!pattern) {
            return Promise.resolve(referralsOptions)
        }
        return loadReferrals(pattern).then((data) => {
            return data.items.map((item) => ({
                value: item.code,
                label: item.name,
            }))
        })
    }

    function autocompleteSupplier(pattern) {
        if (!pattern) {
            return Promise.resolve([])
        }
        const result = [].filter((item) => {
            return item.label.toLowerCase().includes(pattern.toLowerCase())
        })
        return Promise.resolve(result)
    }

    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <SelectField
                width="col-2"
                options={allReporters}
                value={reporter || ""}
                onChange={setReporterFilter}
            />
            <AutocompleteSelectField
                width="col-2"
                options={referralsOptions}
                value={referral?.value || ""}
                load={autocompleteReferrals}
                onChange={setReferralFilter}
            />
            <AutocompleteSelectField
                width="col-2"
                options={allSuppliers}
                value={""}
                load={autocompleteSupplier}
                onChange={todoSupplier}
            />
            <SelectField
                width="col-2"
                options={filterAnalysisTypes}
                value={type || ""}
                onChange={setTypeFilter}
            />
            <InlineSearch onChange={onChangeSearch} />
        </div>
    )
}

export default SupervisorDashboardFilter
