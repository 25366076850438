export function isAssignable(analysis) {
    return ["Analysed", "Assigned"].includes(analysis.status)
}

export function canBeFinalized(analysis) {
    return ["Analysed", "Assigned"].includes(analysis.status)
}

export function isComplete(analysis) {
    return ["Analysed", "Assigned", "Done"].includes(analysis.status)
}

export function isDone(analysis) {
    return analysis.status === "Done"
}

export function canBeRegistered(analysis) {
    return analysis.status === "NotYetRegistered"
}

export function barcodeCanBeUpdated(analysis) {
    return ["NotYetRegistered", "Registered"].includes(analysis.status)
}
