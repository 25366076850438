import React from "react"

function getWithPrefix(value, prefixNumeratorWithSign) {
    if (prefixNumeratorWithSign) {
        return value > 0 ? `+${value}` : value
    }
    return value
}

const SummaryRatio = ({
    denominator,
    numerator,
    rank = "neutral",
    editable = false,
    onSave = () => {},
    onReset = () => {},
    separator = " / ",
    prefixNumeratorWithSign = false,
}) => {
    return (
        <div className="values">
            {editable ? (
                <div className="ratio-reset" onClick={onReset}>
                    Reset
                </div>
            ) : null}
            <h3>{numerator.name}</h3>
            <span
                contentEditable={editable}
                onBlur={
                    editable ? (e) => onSave(e.target.innerText) : undefined
                }
                className={`numerator rank-${rank}`}>
                {getWithPrefix(numerator.value, prefixNumeratorWithSign)}
            </span>
            {separator}
            <h3>{denominator.name}</h3>
            <span className={`denominator rank-${rank}`}>
                {denominator.value}
            </span>
        </div>
    )
}

export default SummaryRatio
