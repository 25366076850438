import React, { useState } from "react"
import useFormist from "react-formist"
import * as yup from "yup"
import SectionMetadataEdit from "./section-metadata-edit"
import { CommentAction } from "@atlaskit/comment"
import { b64EncodeUnicode, b64DecodeUnicode } from "../../shared/base64"
import SectionListHormonesValuesEdit from "./section-list-hormonesvalues-edit"
import HormonPointsEdit from "./hormon-points-edit"
import TableField from "../shared/components/tablefield"
import Tabs from "@atlaskit/tabs"
import LocalizedTextField from "../shared/components/localized-textfield"
import { hormonesSchema } from "../hormones/schemas"
import {
    SectionHormonesDetailsWithSpecifierEdit,
    decodeAnalysisCodeWithSpecifiers,
    getHormonesDetails,
    saveAnalysisCodesWithSpecifiers,
} from "./section-hormonesdetails-edit"
import { SelectField } from "../shared/components/selectfield"
import RankedRangeListEdit from "./ranked-range-list-edit"

const typeOptions = [
    {
        value: "Longevity",
        label: "Longevity",
    },
    {
        value: "VitalRate",
        label: "Vitality Rate",
    },
]

const exerciseSchema = [
    {
        field: "frequency",
        label: "Frequency",
        type: "select",
        options: [
            {
                label: "Low",
                value: "low",
            },
            {
                label: "Average",
                value: "average",
            },
            {
                label: "High",
                value: "high",
            },
        ],
    },
    {
        field: "points",
        label: "Points",
    },
]

const smokerSchema = [
    {
        field: "type",
        label: "Type",
        type: "select",
        options: [
            {
                label: "Yes",
                value: "yes",
            },
            {
                label: "No",
                value: "no",
            },
        ],
    },
    {
        field: "points",
        label: "Points",
    },
]

const conversionSchema = [
    {
        field: "low",
        label: "Low",
    },
    {
        field: "high",
        label: "High",
    },
    {
        field: "points",
        label: "Points",
    },
]

function getConversion(s) {
    const parts = s.split(";")
    return {
        low: parts[0],
        high: parts[1],
        points: parts[2],
    }
}

const SectionMultiGaugeHormonesEdit = ({
    section,
    hormones = [],
    onSave,
    onCancel,
}) => {
    const hormonesSuggestions = hormones.reduce(
        (acc, h) => [...acc, h.name, ...h.variants.map((v) => v.name)],
        [],
    )

    const schema = yup.object().shape({
        id: yup.string().required(),
        name: yup
            .string()
            .test(
                "valid-name",
                "name is a required field",
                (value) => value.replace("#", "").trim() !== "",
            ),
        htmlElementId: yup.string().required(),
        type: yup.string().required(),
        index: yup.number().min(0).required(),
        description: yup.string(),
        hormones: hormonesSchema(hormonesSuggestions),
        hormonesDetails: yup.object(),
        agePoints: yup.array().of(yup.string().required()),
        bmiPoints: yup.array().of(yup.string().required()),
        exercisePoints: yup.object(),
        smokePoints: yup.object(),
        conversionPoints: yup.object(),
        multiGaugeType: yup.string().required(),
        ranks: yup.array().of(yup.string().required()),
    })

    const decodedHormones = section.hormones
        .map(b64DecodeUnicode)
        .map(decodeAnalysisCodeWithSpecifiers)

    const model = {
        ...section,
        hormones: decodedHormones.map((h) => h.code),
        hormonesDetails: getHormonesDetails(decodedHormones),
        agePoints: section.agePoints.map(b64DecodeUnicode),
        bmiPoints: section.bmiPoints.map(b64DecodeUnicode),
        conversionPoints: Object.keys(section.conversionPoints).reduce(
            (acc, cur) => ({
                ...acc,
                [cur]: section.conversionPoints[cur].map(b64DecodeUnicode),
            }),
            {},
        ),
        ranks: section.ranks.map(b64DecodeUnicode),
    }
    const formist = useFormist(model, {
        schema,
        onSubmit: () => {},
    })

    const onLocalSave = (e) => {
        e.preventDefault()
        formist
            .validate()
            .then((errors) => {
                if (!errors)
                    onSave({
                        ...formist.values,
                        hormones: saveAnalysisCodesWithSpecifiers(
                            formist.values.hormones,
                            formist.values.hormonesDetails,
                        ),
                        agePoints:
                            formist.values.agePoints.map(b64EncodeUnicode),
                        bmiPoints:
                            formist.values.bmiPoints.map(b64EncodeUnicode),
                        conversionPoints: Object.keys(
                            formist.values.conversionPoints,
                        ).reduce(
                            (acc, curr) => ({
                                ...acc,
                                [curr]: formist.values.conversionPoints[
                                    curr
                                ].map(b64EncodeUnicode),
                            }),
                            {},
                        ),
                        ranks: formist.values.ranks.map(b64EncodeUnicode),
                    })
            })
            .catch(() => {})
    }

    const actualHormones = (
        formist.getFieldProps("hormones").value || []
    ).filter((h) => hormonesSuggestions.indexOf(h) !== -1)

    const onExerciseChange = (e) => {
        formist.getFieldProps("exercisePoints").onChange({
            target: {
                value: e.target.value
                    ? e.target.value.reduce(
                          (acc, cur) => ({
                              ...acc,
                              [cur.frequency]: cur.points,
                          }),
                          {},
                      )
                    : {},
            },
        })
    }

    const onSmokerChange = (e) => {
        formist.getFieldProps("smokePoints").onChange({
            target: {
                value: e.target.value
                    ? e.target.value.reduce(
                          (acc, cur) => ({
                              ...acc,
                              [cur.type]: cur.points,
                          }),
                          {},
                      )
                    : {},
            },
        })
    }

    function onConversionChange(specifier) {
        return function (e) {
            formist.getFieldProps("conversionPoints").onChange({
                target: {
                    value: {
                        ...formist.getFieldProps("conversionPoints").value,
                        [specifier]: e.target.value.map(
                            (v) => `${v.low};${v.high};${v.points}`,
                        ),
                    },
                },
            })
        }
    }

    const tabs = ["male", "female"].map(function (s) {
        return {
            label: { male: "Man", female: "Woman" }[s],
            content: (
                <>
                    <div className="form-row">
                        <TableField
                            key={s}
                            label=""
                            width="col"
                            editable
                            moveRows
                            schema={conversionSchema}
                            value={(
                                formist.getFieldProps("conversionPoints").value[
                                    s
                                ] || []
                            ).map(getConversion)}
                            onChange={onConversionChange(s)}
                        />
                    </div>
                </>
            ),
        }
    })

    const [selectedTab, setSelectedTab] = useState(0)

    return (
        <div className="form-group">
            <SectionMetadataEdit formist={formist} />
            <div className="form-row">
                <LocalizedTextField
                    label="Description"
                    width="col"
                    {...formist.getFieldProps("description")}
                />
                <SelectField
                    label="Gauge Type"
                    width="col"
                    {...formist.getFieldProps("multiGaugeType")}
                    options={typeOptions}
                />
            </div>
            <h3>Hormones</h3>
            <SectionListHormonesValuesEdit
                hormonesField={formist.getFieldProps("hormones")}
                errors={formist.errors}
                suggestions={hormonesSuggestions}
            />
            <SectionHormonesDetailsWithSpecifierEdit
                details={formist.getFieldProps("hormonesDetails")}
                hormones={actualHormones}
            />
            <div className="form-row">
                <HormonPointsEdit
                    title="Ages"
                    field={{
                        value: formist.getFieldProps("agePoints").value,
                        onChange: formist.getFieldProps("agePoints").onChange,
                    }}
                />
                <HormonPointsEdit
                    title="BMI"
                    field={{
                        value: formist.getFieldProps("bmiPoints").value,
                        onChange: formist.getFieldProps("bmiPoints").onChange,
                    }}
                />
            </div>
            <div className="form-row">
                <h3>Exercise Frequency</h3>
                <div className="form-row">
                    <TableField
                        label=""
                        width="col"
                        editable
                        moveRows
                        schema={exerciseSchema}
                        value={Object.keys(
                            formist.getFieldProps("exercisePoints").value,
                        ).map((f) => ({
                            frequency: f,
                            points: formist.getFieldProps("exercisePoints")
                                .value[f],
                        }))}
                        onChange={onExerciseChange}
                    />
                </div>
                <h3>Smoker</h3>
                <div className="form-row">
                    <TableField
                        label=""
                        width="col"
                        editable
                        moveRows
                        schema={smokerSchema}
                        value={Object.keys(
                            formist.getFieldProps("smokePoints").value,
                        ).map((t) => ({
                            type: t,
                            points: formist.getFieldProps("smokePoints").value[
                                t
                            ],
                        }))}
                        onChange={onSmokerChange}
                    />
                </div>
            </div>
            <h3>Conversion</h3>
            <Tabs
                tabs={tabs}
                onSelect={(tab, idx) => setSelectedTab(idx)}
                selected={selectedTab}
            />
            <div className="form-row section-edit-actions">
                <CommentAction key="1" onClick={onLocalSave}>
                    Save
                </CommentAction>
                &nbsp;
                <CommentAction key="2" onClick={onCancel}>
                    Cancel
                </CommentAction>
            </div>
            <h3>Final Points</h3>
            <div className="form-row">
                <RankedRangeListEdit
                    freeRanks
                    withLabel
                    field={{
                        ...formist.getFieldProps("ranks"),
                    }}
                    errors={formist.getFieldProps("ranks").error}
                />
            </div>
        </div>
    )
}

export default SectionMultiGaugeHormonesEdit
