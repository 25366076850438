import dayjs from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"

dayjs.extend(customParseFormat)

const ITA = "DD/MM/YYYY"
const ISO = "YYYY-MM-DD"

export const parse = (date) => dayjs(date, ITA)

export function isDateValid(date) {
    return parse(date).isValid()
}

export function calcAge(birthDate, today = dayjs()) {
    return today.diff(parse(birthDate), "year")
}

export function addDays(days, today = dayjs()) {
    return toIta(today.add(days, "day"))
}

export function toDays(start, end) {
    return parse(end).diff(parse(start), "day")
}

export const toIso = (date) =>
    isDateValid(date) ? parse(date).format(ISO) : ""
export const toIta = (date) => dayjs(date).format(ITA)
export const today = toIta(dayjs())
