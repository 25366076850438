import { apis } from "../../shared/apis"
import { actions } from "./todo-store"

export function mkApi(dispatch) {
    return {
        loadToDos({
            pageNumber,
            pageSize,
            sortBy,
            type = "",
            referral,
            patient,
            pattern,
        }) {
            return apis.reporters
                .loadTodos(
                    pageNumber,
                    pageSize,
                    sortBy,
                    `${type}:${referral?.value || ""}:${patient?.value || ""}`,
                    pattern,
                )
                .then((data) =>
                    dispatch(
                        actions.load({
                            ...data,
                            type,
                            referral,
                            patient,
                            pattern,
                        }),
                    ),
                )
                .catch((error) => dispatch(actions.error(error)))
        },
        loadDone({
            pageNumber,
            pageSize,
            sortBy,
            reporter = "",
            type = "",
            referral,
            patient,
            from,
            pattern,
        }) {
            return apis.reporters
                .loadDone(
                    pageNumber,
                    pageSize,
                    sortBy,
                    `${reporter}:${type}:${referral?.value || ""}:${
                        patient?.value || ""
                    }:${from}`,
                    pattern,
                )
                .then((data) =>
                    dispatch(
                        actions.load({
                            ...data,
                            reporter,
                            type,
                            referral,
                            patient,
                            from,
                            pattern,
                        }),
                    ),
                )
                .catch((error) => dispatch(actions.error(error)))
        },
        loadReferrals(pattern) {
            return apis.referrals.search(pattern, 1, 1000)
        },
        loadPatients(pattern) {
            return apis.patients.search(pattern, 1, 10)
        },
        markAsDone(barcode) {
            return apis.reporters.markAsDone(barcode)
        },
        loadLayouts() {
            return apis.layouts.loadAll(1, 1000)
        },
        loadReporters() {
            return apis.users.loadByRole("reporter")
        },
        downloadPDF(barcode, layout, reportMode) {
            return apis.geneticAnalyses.url.downloadPDF(
                barcode,
                layout,
                reportMode,
            )
        },
        pdf(barcode, layout) {
            return apis.geneticAnalyses.createPDF(barcode, layout)
        },
        allpdf(barcode) {
            return apis.geneticAnalyses.createAllPDF(barcode)
        },
        sendEmail(
            barcode,
            layout,
            to,
            patientAttachmentRule,
            referralAttachmentRule,
        ) {
            return apis.reports.sendEmail(
                barcode,
                layout,
                to,
                patientAttachmentRule,
                referralAttachmentRule,
            )
        },
        removeBarcode(barcode, force) {
            return apis.geneticAnalyses
                .removeBarcode(barcode, force)
                .then((res) => {
                    if (res.ajaxError) throw res.ajaxError
                    return res
                })
        },
    }
}
