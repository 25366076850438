import React from "react"
import DynamicTableStateless from "@atlaskit/dynamic-table"
import Pagination from "@atlaskit/pagination"

const PaginatedTable = ({
    head,
    rows,
    isLoading,
    pages,
    onChangePage,
    onChangePageSize = () => {},
    pageNumber = 1,
    type = "dashboard",
    pageSizeOptions = [15],
    pageSize = 15,
}) => {
    return (
        <div className={`${type}-table`}>
            <DynamicTableStateless
                head={head}
                rows={rows}
                loadingSpinnerSize="large"
                isLoading={isLoading}
                isFixedSize
            />
            <div className="row justify-content-center">
                <div className="col-md-auto">
                    <Pagination
                        pages={pages}
                        onChange={onChangePage}
                        selectedIndex={pageNumber - 1}
                    />
                    {pageSizeOptions.length > 1 && (
                        <div className="mt-2">
                            <span>Items per page: </span>
                            <select
                                onChange={(e) =>
                                    onChangePageSize(e.target.value)
                                }>
                                {pageSizeOptions.map((size) => (
                                    <option
                                        key={size}
                                        selected={size === pageSize}
                                        value={size}>
                                        {size}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default PaginatedTable
