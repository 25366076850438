import React from "react"
import { getLocalizedText } from "../../../../shared/locale"
import { polarToCartesian } from "../gauge"

function describeSlice(center, radius, startAngle, endAngle) {
    const start = polarToCartesian(center, radius, endAngle)
    const end = polarToCartesian(center, radius, startAngle)

    const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1"

    return [
        "M",
        start.x,
        start.y,
        "A",
        radius,
        radius,
        0,
        largeArcFlag,
        0,
        end.x,
        end.y,
        "L",
        center.x,
        center.y,
        "L",
        start.x,
        start.y,
        "Z",
    ].join(" ")
}

function getSliceTick(tick, min, max, minAngle, maxAngle, center) {
    const angle =
        minAngle + ((tick - min) * (maxAngle - minAngle)) / (max - min)
    const radius = 100
    const start = polarToCartesian(center, radius, angle)
    const end = polarToCartesian(center, radius + 3, angle)
    const label = polarToCartesian(center, radius + 10, angle)
    return (
        <>
            <line
                className={`ratio-slice-tick`}
                x1={start.x}
                y1={start.y}
                x2={end.x}
                y2={end.y}
            />
            <text
                className={`ratio-slice-tick-label`}
                transform={`translate(${label.x} ${label.y})`}>
                <tspan x="0" y="0">
                    {tick}
                </tspan>
            </text>
        </>
    )
}

function getSliceLine(tick, min, max, minAngle, maxAngle, center) {
    const angle =
        minAngle + ((tick - min) * (maxAngle - minAngle)) / (max - min)
    const radius = 100
    const end = polarToCartesian(center, radius, angle)
    return (
        <>
            <line
                className={`ratio-slice-line`}
                x1={center.x}
                y1={center.y}
                x2={end.x}
                y2={end.y}
            />
        </>
    )
}

const ranks = ["good", "bad", "neutral"]

const SliceRatio = ({
    numerator,
    denominator,
    rank = "neutral",
    min = 0.6,
    max = 1.4,
    step = 0.1,
    language,
    minLabel = "",
    maxLabel = "",
    minAngle = 0,
    maxAngle = 90,
}) => {
    const ticks = Array.from({ length: (max - min) / step + 1 }, (_, i) =>
        (min + i * step).toFixed(2),
    )
    const radius = 100
    const center = { x: 0, y: 100 }
    const value = denominator.value
    const angle =
        minAngle + ((max - value) * (maxAngle - minAngle)) / (max - min)
    const middleAngle = (minAngle + maxAngle) / 2.0
    const angle1 = angle > middleAngle ? middleAngle : angle
    const angle2 = angle > middleAngle ? angle : middleAngle
    const middlePoint = polarToCartesian(center, radius, middleAngle)
    return (
        <div className="slice-ratio-container">
            <div>
                <svg
                    className="slice-ratio-chart"
                    viewBox="0 0 100 100"
                    preserveAspectRatio="none">
                    <defs>
                        {ranks.map((rank) => (
                            <pattern
                                key={rank}
                                id={`pattern-${rank}`}
                                patternUnits="userSpaceOnUse"
                                width="4"
                                height="4"
                                patternTransform="scale(0.5 0.5) rotate(90 0 0)">
                                <path
                                    d="M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2"
                                    style={{
                                        stroke: `var(--rank-${rank})`,
                                        strokeWidth: 0.5,
                                    }}
                                />
                            </pattern>
                        ))}
                    </defs>
                    <path
                        className="ratio-slice-external"
                        d={describeSlice(center, radius, minAngle, maxAngle)}
                    />
                    <text
                        className={`ratio-slice-label ratio-slice-numerator-label`}
                        transform={`translate(0 ${radius}) rotate(${
                            minAngle - 90
                        })`}>
                        <tspan x="25" y="-5">
                            {getLocalizedText(numerator.name, language)}
                        </tspan>
                    </text>
                    <text
                        className={`ratio-slice-label ratio-slice-denominator-label`}
                        transform={`translate(0 ${radius}) rotate(${
                            maxAngle - 90
                        })`}>
                        <tspan x="20" y="8">
                            {getLocalizedText(denominator.name, language)}
                        </tspan>
                    </text>
                    {ticks.map((tick) =>
                        getSliceTick(
                            tick,
                            min,
                            max,
                            maxAngle,
                            minAngle,
                            center,
                        ),
                    )}
                    {ticks
                        .splice(1, ticks.length - 2)
                        .map((tick) =>
                            getSliceLine(
                                tick,
                                min,
                                max,
                                maxAngle,
                                minAngle,
                                center,
                            ),
                        )}
                    <text
                        className={`ratio-slice-tick-label ratio-slice-max`}
                        transform={`translate(70 0)`}
                        textAnchor="middle">
                        <tspan x="0" y="8">
                            {maxLabel}
                        </tspan>
                    </text>
                    <text
                        className={`ratio-slice-tick-label ratio-slice-min`}
                        transform={`translate(70 185)`}
                        textAnchor="middle">
                        <tspan x="0" y="8">
                            {minLabel}
                        </tspan>
                    </text>
                    <path
                        className={`ratio-slice-value ratio-slice-value-${rank}`}
                        d={describeSlice(center, radius, angle1, angle2)}
                    />
                    <line
                        className={`ratio-slice-middle-line`}
                        x1={center.x}
                        y1={center.y}
                        x2={middlePoint.x}
                        y2={middlePoint.y}
                    />
                </svg>
            </div>
        </div>
    )
}

export default SliceRatio
