import React from "react"
import HormonSpecifiersRangesEdit from "./hormon-specifiers-ranges-edit"
import HormonRangesEdit from "./hormon-ranges-edit"
import { b64EncodeUnicode, b64DecodeUnicode } from "../../shared/base64"
import { hormonSpecifiers } from "../hormones/schemas"
import { uniq } from "lodash"

export function SectionHormonesDetailsWithSpecifierEdit({ hormones, details }) {
    const allHormones = uniq([...hormones, ...Object.keys(details.value)])
    return allHormones.map((h) => (
        <HormonSpecifiersRangesEdit
            visible={hormones.includes(h)}
            key={h}
            hormon={h}
            field={{
                value: details.value[h] || {
                    code: h,
                    attributes: fillMissingSpecifiers({}),
                },
                onChange: (v) => {
                    details.onChange({
                        target: {
                            value: hormones.reduce(
                                (acc, hormon) => ({
                                    ...acc,
                                    [hormon]:
                                        hormon === h
                                            ? v.target.value
                                            : details.value[hormon],
                                }),
                                {},
                            ),
                        },
                    })
                },
            }}
        />
    ))
}

export function SectionHormonesDetailsEdit({ hormones, details }) {
    const allHormones = uniq([...hormones, ...Object.keys(details.value)])
    return allHormones.map((h) => (
        <HormonRangesEdit
            visible={hormones.includes(h)}
            key={h}
            hormon={h}
            field={{
                value: details.value[h] || [],
                onChange: (v) => {
                    details.onChange({
                        target: {
                            value: hormones.reduce(
                                (acc, hormon) => ({
                                    ...acc,
                                    [hormon]:
                                        hormon === h
                                            ? v.target.value
                                            : details.value[hormon],
                                }),
                                {},
                            ),
                        },
                    })
                },
            }}
        />
    ))
}

export function getHormonesDetails(hormones) {
    return hormones.reduce(
        (acc, h) => ({
            ...acc,
            [h.code]: h,
        }),
        {},
    )
}

export function getHormonesDetailsWithSpecifiers(hormones) {
    return Object.keys(hormones).reduce(
        (acc, h) => ({
            ...acc,
            [h]: hormones[h].map(b64DecodeUnicode),
        }),
        {},
    )
}

export function saveHormonesWithSpecifiers(hormones, details) {
    return hormones
        .map((h) =>
            encodeHormonWithSpecifiers(
                details[h] || {
                    code: h,
                    attributes: fillMissingSpecifiers({}),
                },
            ),
        )
        .map(b64EncodeUnicode)
}

export function saveAnalysisCodesWithSpecifiers(hormones, details) {
    return hormones
        .map((h) =>
            encodeHormonWithSpecifiers(
                details[h]
                    ? {
                          ...details[h],
                          code: `Hormon:${details[h].code}`,
                      }
                    : {
                          code: `Hormon:${h}`,
                          attributes: fillMissingSpecifiers({}),
                      },
            ),
        )
        .map(b64EncodeUnicode)
}

export function saveHormones(hormones, details) {
    return hormones.reduce(
        (acc, h) => ({
            ...acc,
            [h]: details[h] ? details[h].map(b64EncodeUnicode) : [],
        }),
        {},
    )
}

export function decodeHormonWithSpecifiers(hormon) {
    const parts = hormon.split(":")
    const code = parts[0]
    return {
        code,
        attributes: fillMissingSpecifiers(
            parts[1]
                ? parts[1].split("#").reduce((acc, s) => {
                      const specParts = s.split("$")
                      const specifier = specParts[0]
                      const attributes = specParts[1]
                          ? specParts[1].split(",").map((a) => {
                                const attrParts = a.split(";")
                                return {
                                    range: {
                                        low: attrParts[0],
                                        high: attrParts[1],
                                    },
                                    rank: attrParts[2],
                                }
                            })
                          : []
                      return {
                          ...acc,
                          [specifier]: attributes,
                      }
                  }, {})
                : {},
        ),
    }
}

export function decodeAnalysisCodeWithSpecifiers(hormon) {
    const parts = hormon.split(":")
    const code = parts[1]
    return {
        code,
        attributes: fillMissingSpecifiers(
            parts[1]
                ? parts[2].split("#").reduce((acc, s) => {
                      const specParts = s.split("$")
                      const specifier = specParts[0]
                      const attributes = specParts[1]
                          ? specParts[1].split(",").map((a) => {
                                const attrParts = a.split(";")
                                return {
                                    range: {
                                        low: attrParts[0],
                                        high: attrParts[1],
                                    },
                                    rank: attrParts[2],
                                }
                            })
                          : []
                      return {
                          ...acc,
                          [specifier]: attributes,
                      }
                  }, {})
                : {},
        ),
    }
}

export function fillMissingSpecifiers(attributes) {
    return Object.keys(hormonSpecifiers).reduce((acc, s) => {
        if (acc[s]) {
            return acc
        }
        return {
            ...acc,
            [s]: [],
        }
    }, attributes)
}

export function encodeHormonWithSpecifiers(hormon) {
    return (
        hormon.code +
        ":" +
        Object.keys(hormonSpecifiers)
            .map(
                (s) =>
                    s +
                    "$" +
                    hormon.attributes[s]
                        .map(
                            (a) =>
                                a.range.low + ";" + a.range.high + ";" + a.rank,
                        )
                        .join(","),
            )
            .join("#")
    )
}
