import React from "react"
import Wizard from "../shared/components/wizard"
import PatientPhysicalDescriptionForm, {
    detailsToModel,
    modelToDetails,
} from "../patients/patient-physical"
import { withRouter } from "react-router"
import useFormist from "react-formist"
import { getItem } from "./storage"
import { routes } from "../../shared/routing"
import { apis } from "../../shared/apis"
import { mergeItem } from "./storage"
import { undo } from "./form"
import { physicalDescriptionSchema as schema } from "../patients/schemas"
import { model as empty } from "../patients/patient-physical"
import { calcAge } from "../../shared/dates"

const RegisterPatientPhysicalDescription = ({ history, match }) => {
    const barcode = match.params.id
    const geneticAnalysis = getItem(barcode)
    const age =
        geneticAnalysis.patientData && geneticAnalysis.patientData.bornOn
            ? calcAge(geneticAnalysis.patientData.bornOn)
            : undefined

    const anonymous =
        (geneticAnalysis.patientData &&
            geneticAnalysis.patientData.anonymous) ||
        false
    const initialData =
        (geneticAnalysis.patientDetails &&
            geneticAnalysis.patientDetails.physicalDescription &&
            detailsToModel(
                geneticAnalysis.patientDetails.physicalDescription,
            )) ||
        empty

    const initialDataWithAge = {
        ...initialData,
        age: age || initialData.age,
    }
    const formist = useFormist(initialDataWithAge, {
        schema,
        onSubmit: (data) => {
            const toSave = modelToDetails(data)
            apis.patients.physicalDescription
                .updateOne(geneticAnalysis.patientId, barcode, toSave)
                .then(() => {
                    mergeItem(barcode, {
                        patientDetails: {
                            ...geneticAnalysis.patientDetails,
                            physicalDescription: toSave,
                        },
                    })
                    history.push(
                        routes.geneticAnalyses.registerPatientGuardian.buildUrl(
                            barcode,
                        ),
                    )
                })
        },
    })

    return (
        <Wizard
            title={`Physical Description for: ${barcode}`}
            onUndo={undo(history, barcode)}
            onPrevious={() => history.goBack()}
            {...formist.getFormProps()}>
            <PatientPhysicalDescriptionForm
                formist={formist}
                anonymous={anonymous}
            />
        </Wizard>
    )
}

export default withRouter(RegisterPatientPhysicalDescription)
