import React, { useEffect, useState } from "react"
import ModalDialog from "@atlaskit/modal-dialog"
import Button from "@atlaskit/button"
import Form from "../shared/components/form"
import { SelectField } from "../shared/components/selectfield"

export default function AssignReporter({
    enabled = false,
    onConfirm,
    onCancel,
    reporter = "",
    barcodes = [],
    reporters = [],
}) {
    const [newReporter, setNewReporter] = useState("")
    useEffect(() => setNewReporter(reporter), [reporter])
    const reporterOptions = [
        {
            label: "Unassigned",
            value: "",
        },
        ...reporters.map((r) => ({
            label: `${r.name} (${r.email})`,
            value: r.email,
        })),
    ]
    return enabled ? (
        <ModalDialog
            height={500}
            id="assign-reporter-dialog"
            heading={`Assign Reporter to ${
                barcodes.length === 1 ? barcodes[0] : "selected barcodes"
            }`}>
            <Form
                title=""
                size="full"
                buttons={[
                    <Button
                        key="confirm"
                        appearance="primary"
                        onClick={() => onConfirm(newReporter)}>
                        Assign
                    </Button>,
                    "cancel",
                ]}
                primaryText="Change"
                onCancel={onCancel}>
                <div className="form-row">
                    <SelectField
                        label="Reporter"
                        width="col"
                        options={reporterOptions}
                        value={newReporter}
                        onChange={(e) => setNewReporter(e.target.value)}
                    />
                </div>
            </Form>
        </ModalDialog>
    ) : null
}
