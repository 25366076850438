import React from "react"

const indexShapes = {
    simple: () => (
        <path d="M236,120H223.66406A96.15352,96.15352,0,0,0,136,32.33618V20a8,8,0,0,0-16,0V32.33618A96.15352,96.15352,0,0,0,32.33594,120H20a8,8,0,0,0,0,16H32.33594A96.15352,96.15352,0,0,0,120,223.66382V236a8,8,0,0,0,16,0V223.66382A96.15352,96.15352,0,0,0,223.66406,136H236a8,8,0,0,0,0-16Zm-40,16h11.59912A80.14164,80.14164,0,0,1,136,207.59912V196a8,8,0,0,0-16,0v11.59912A80.14164,80.14164,0,0,1,48.40088,136H60a8,8,0,0,0,0-16H48.40088A80.14164,80.14164,0,0,1,120,48.40088V60a8,8,0,0,0,16,0V48.40088A80.14164,80.14164,0,0,1,207.59912,120H196a8,8,0,0,0,0,16Zm-28-8a40,40,0,1,1-40-40A40.04552,40.04552,0,0,1,168,128Z" />
    ),
    arthro: () => (
        <g>
            <circle cx={120} cy={120} r={80} className="external-circle" />
            <circle cx={120} cy={120} r={50} className="internal-circle" />
        </g>
    ),
}

const RatioIndex = ({
    id,
    denominator,
    numerator,
    scale = 0.08,
    ratioStyle,
}) => {
    const IndexShape = indexShapes[ratioStyle]
    return (
        <g
            className={`ratio-index ratio-index-${id} ratio-index-${ratioStyle}`}
            style={{
                overflow: "visible",
                transform: `translate(${denominator.value - 10}px, ${
                    90.0 - numerator.value
                }px) scale(${scale}, ${scale})`,
            }}>
            <IndexShape />
        </g>
    )
}

export default RatioIndex
