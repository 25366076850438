import { apis } from "../../shared/apis.js"
import { actions } from "./store.js"

export function mkApi(dispatch) {
    return {
        loadByStatus(
            {
                pageNumber,
                pageSize,
                sortBy,
                type,
                pattern,
                status,
                referral,
                reporter,
            },
            append = false,
        ) {
            return apis.geneticAnalyses
                .loadByStatus(
                    pageNumber,
                    pageSize,
                    sortBy,
                    `${type ?? ""}:${referral || ""}:${reporter || ""}`,
                    pattern,
                    status,
                )
                .then((data) => {
                    dispatch(
                        actions.loadByStatus({
                            ...data,
                            status,
                            append,
                            type,
                            referral,
                            reporter,
                            pattern,
                        }),
                    )
                })
        },
        loadReporters() {
            return apis.users.loadByRole("reporter")
        },
        loadReferrals(pattern) {
            return apis.referrals.search(pattern, 1, 1000)
        },
        assignReporter(barcodes, reporter) {
            return apis.geneticAnalyses.assignReporter(barcodes, reporter)
        },
        unassignReporter(barcodes) {
            return apis.geneticAnalyses.unassignReporter(barcodes)
        },
    }
}
